<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.printer_configs')">

            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.ConfigurationWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('config.printer_configs.add_printer_config') }}
                </b-button>
            </div>

            <Table class="mt-1" :columnDefs="columnDefs" :rowData="printerConfigs" @deleteRow="remove" @editRow="editRow" @edit="edit"/>
        </b-card>

        <AddPrinterConfig ref="addModal" v-on:itemAdded="loadData" :business-units-array="businessUnitsArray" :subcategories-array="subcategoriesArray"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddPrinterConfig from '@/views/PrinterConfig/AddPrinterConfig'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'
    import i18n from '@/i18n/i18n'

    export default {
        mixins: [itemChange],
        components: {Table, AddPrinterConfig, BCard, BOverlay, BButton},
        computed: {
            paperWidthTypes() {
                return [
                    {id: 0, name: `${i18n.t('config.printer_configs.width_types.48chars')}`},
                    {id: 1, name: `${i18n.t('config.printer_configs.width_types.42chars')}`},
                    {id: 2, name: `${i18n.t('config.printer_configs.width_types.58mm')}`}
                ]
            },

            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.ConfigurationWrite)
                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: hasPermission, field: 'name', filter: true},
                    { headerName: this.$t('table_fields.paper_width_type'), editable: false, field: 'paper_width_type', filter: true, cellRenderer: (params) => this.getPaperWidthTypes(params.value), cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.paperWidthTypes, selectValue: 'name' } }, filterValueGetter: (data) => this.getPaperWidthTypes(data.data.paper_width_type), floatingFilterComponentParams: () => { return {type: 'select', values: this.paperWidthTypes, selectValue: 'name'} }},
                    { headerName: this.$t('table_fields.interface'),
                      editable: (x) => {
                          return hasPermission && x.data.interface_type !== 3
                      },
                      field: 'interface',
                      filter: true},
                    { headerName: this.$t('table_fields.interface_type'), editable: false, field: 'interface_type', filter: true, cellRenderer: (params) => this.getInterfaceTypes().find(item => item.id === params.value)?.name ?? '/', cellEditorFramework: 'selectEditor', cellEditorParams: {values: this.getInterfaceTypes()}, floatingFilterComponentParams: {type: 'select', values: this.getInterfaceTypes()}}
                ]

                if (hasPermission) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                printerConfigs: [],
                businessUnitsArray:[],
                subcategoriesArray:[],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/printer_config/')
                    this.printerConfigs = response.data ?? []
                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async loadBusinessUnits() {
                try {
                    const response = await this.$http.get('/api/client/v1/business_units/')
                    this.businessUnitsArray = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(err.response)
                }
            },
            async loadSubcategories() {
                try {
                    const response = await this.$http.get('/api/client/v1/product_subcategories/')
                    this.subcategoriesArray = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(err.response)
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/printer_config/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/printer_config/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getPaperWidthTypes(value) {
                return this.paperWidthTypes.find(x => x.id === value).name ?? '/'
            },
            getInterfaceTypes() {
                return [
                    {id: 0, name: `${i18n.t('config.printer_configs.interface_types.tcp_ip')}`},
                    {id: 1, name: `${i18n.t('config.printer_configs.interface_types.usb')}`},
                    {id: 2, name: `${i18n.t('config.printer_configs.interface_types.win_print_service')}`},
                    {id: 3, name: `${i18n.t('config.printer_configs.interface_types.sunmi_inner_printer')}`}
                ]
            }
        },
        async mounted() {
            await this.loadData()
            await this.loadBusinessUnits()
            await this.loadSubcategories()
        }
    }
</script>

<style scoped>

</style>
