import i18n from '@/i18n/i18n'

export const FontSize = {
    Small: 0,
    Big: 1,
    BigCompressed: 2
}

export const FontSizeNames = [
    {name: i18n.t('config.small'), value: FontSize.Small},
    {name: i18n.t('config.big'), value: FontSize.Big},
    {name: i18n.t('config.big_compressed'), value: FontSize.BigCompressed}
]