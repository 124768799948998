<template>
    <div class="modal-header-tabs">
        <div class="container">
            <div @click="clickTab(index)" :class="{ active: isTabActive(index) }"  class="customTab px-1" v-for="(tab, index) in tabs" :key="index">
                <h5> {{ tab.title }} </h5>
            </div>
        </div>
        <b-button @click="$emit('close')" class="transparent-button" variant="secondary">
            <span class="close" aria-hidden="true">&times;</span>
        </b-button>
    </div>
</template>
<script>

    import {BButton} from 'bootstrap-vue'

    export default {
        components: {
            BButton
        },
        props: {
            activeTab:{
                type: Number,
                default: 0
            },
            tabs: {
                type: Array
            }
        },
        data() {
            return {
            }
        },
        methods: {
            clickTab(index) {
                this.$emit('clickTab', index)
            },
            isTabActive(index) {
                return this.activeTab === index
            }
        }

    }
</script>
<style scoped>
.container {
  padding-left: 0;
  height: 22px;
  display: flex;
  align-items: center; /* Optional: Aligns items vertically */
}
.modal-header-tabs {
  width: 100%;
  display: flex;
  align-items: center;

}
.customTab {
  transform: translateY(3px);
  display: flex;
  cursor: pointer;
  padding-top: 10px;
  border-bottom: 2px solid transparent;
}
.customTab.active {
  border-bottom: 2px solid #B4BFB5;
  h5 {
    color: #B4BFB5;
  }
}
.transparent-button {
  background-color: transparent !important;
  border: none !important;
  color: inherit !important;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>